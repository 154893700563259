export const profiles = 
[
    {
        "name": "Chase",
        "imgUrl": "IMG_7814.JPG",
        "instaLink": "https://instagram.com/cpalmer_112"
    },
    {
        "name": "Connor",
        "imgUrl": "IMG_5657.jpg",
        "instaLink": "https://instagram.com/csimms1735"
    },
    {
        "name": "Braden",
        "imgUrl": "Braden.jpg",
        "instaLink": "https://instagram.com/braden.palmer"
    }
]